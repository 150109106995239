p {
  text-align: center;
}
/* button {
  border: 1px solid #232e63;
  border-radius: 8px;
  font-size: 16px;
  color: #232e63;
  margin: 4px 0 0 0;
  padding: 4px;
  background-color: white;
} */
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 64px 0;
}
.product {
  width: 40%;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid #232e63;
  border-radius: 8px;
  margin: 8px 16px;
  justify-content:"center"
}
.product h3 {
  text-align: center;
}
.product h5 {
  text-align: center;
  margin: 8px 0;
}
.product p {
  text-align: center;
  margin: 0;
}
.imageContainer {
  aspect-ratio: 1;
  background-size: cover;
  border-radius: 32px;
}
/* .cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #232e63;
  padding: 16px;
  border-radius: 8px;
} */
/* .cart h6 {
  text-align: center;
} */
.cart{
  background-color: #eee;
  color: #111;
  position: fixed;
  max-height: 34px;
  width: 100vw;
  left: 0;
  top: 0;
  padding: 0;
  z-index: 99999;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  -webkit-user-select: none; /* Chrome/Safari */ 
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+ */

/* The rule below is not implemented in browsers yet */
-o-user-select: none;

/* The rule below is implemented in most browsers by now */
user-select: none;
}
.cartBanner{
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.cartButton{
  margin: 16px 0;
}
.open{
  max-height: 50%;
  transition: max-height 0.25s ease-in;
}
.cart img{
  height: 34px;
  margin-right: 8px;
}
.cart h3{
  color: #111;
}
.cartItem{
  margin: 0;
  flex: 1;
}
.cartItem p{
  margin: 0;
}

.lineItem {
  display: flex;
}
.lineItem img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.lineItem h6 {
  margin: 0;
}
.lineItem h5 {
  margin: 4px 0;
  text-align: center;
}
.lineItemDetails {
  margin: 0px 8px;
}
.quantityContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.quantityContainer h5 {
  margin: 0px 8px;
}

/*Overlay*/
.enterPrice {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.enterPrice h2 {
  margin: 0;
}
.enterPrice h2,
.enterPrice h5,
.enterPrice h6 {
  margin: 4px 0;
  text-align: center;
}
.modal {
  background-color: white;
  border-radius: 16px;
  padding: 32px;
}
.modal h3{
  text-align:center
}
input {
  padding: 4px;
  margin: 0 4px;
  border: 1px solid black;
}
#priceInput {
  width: 50px;
  text-align: center;
}
.error {
  color: red;
  margin: 0px;
}
.sizeSelector{
  color: var(--accent-color-1);
  display:flex;
  flex-direction:column;
  align-items:center;
  
}
.sizeLine{
  display:flex;
  align-items:center
}
.sizeLine h5{
  margin: 4px 8px;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* Animating */
.cartImg{
  display:none
}
.send-to-cart.cartImg {
  display: block;
  animation: xAxis 0.4s forwards ease-in;
}

.shake {
  animation: shakeCart 0.4s ease-in-out forwards;
}

@keyframes xAxis {
  0% {
    transform: translate(calc(-40vw), calc(50vh));
    opacity: 0;
  }

  100% {
      transform:translate(0%,0%);
      opacity: 1;
      height: 30px;
  }
}

@keyframes shakeCart {
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}