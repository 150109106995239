.formElement {
    padding: 16px 0 0 0;
    min-width: 200px;
}
.disabledForm{
    opacity: 50%;
}
input{
    margin: 0 16px;
}
textarea{
    margin: 0 16px;
    font-family: 'Open Sans', sans-serif;
}

.formHor{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.barContainer{
    background-color: var(--accent-color-2);
    height: 8px;
    border-radius: 4px;
    display: flex;
    overflow: hidden;
    border: 1px solid var(--accent-color-2);
}
.barFill{
    background-color: var(--accent-color-1);
    height: 100%;
    position: relative;
}