.landingcontainer{
    background-color: var(--accent-color-1);
    max-height: 60vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.teamImages {
    border-radius: 8px;
    flex: 1;
    margin: 8px;
    background-size: cover;
    background-position: center;
    -webkit-filter: grayscale(80%); /* Safari 6.0 - 9.0 */
    filter: grayscale(80%);
    scale: 0.9;
    transition: all .2s ease-in-out;
    display: flex;
    align-items: start;
    justify-content: center;
    color: white;
    text-decoration: none;
    margin-bottom: 16px;
    background-color: var(--accent-color-1);
    border-top: 4px solid var(--accent-color-1);
    border-bottom: 4px solid var(--accent-color-2);
    position: relative;
    overflow: hidden;
    width: 100vw;
    aspect-ratio: 16/9;
    max-height: 60vh;
    
}
.teamImages:hover{
    -webkit-filter: none;
    filter: none;
    scale: 1;
}
.teamImages h2{
    padding: 16px;
    background-color: rgba(20,20,20,0.5);
    border-radius: 8px;
    position: absolute;
    text-align: center;
    align-self: center;
    justify-self: center;
}
.teamSlide{
    display: flex;
    align-items: start;
}
.teamSlide h4{
    background-color: rgba(20,20,20,0.5);
    position: absolute;
    margin-left: 8px;
    padding: 4px 8px;
    border-radius: 8px;
}
.landingimg{
    display: flex;
    width:100%;
    aspect-ratio: 16/9;
    margin: 0;
    overflow: hidden;
    /* * background-image: url(../img/bgimage.jpeg) * */
    align-items: center;
}
.landingimg video{
    height: 100%;
    /* * max-height: 90vh; * */
    opacity: 0.5;
}
.imgoverlay{
    text-align: center;
    position:absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.imgoverlay h1, .imgoverlay h2, .imgoverlay h3, .imgoverlay h4, .imgoverlay h6, .imgoverlay h5{
    color: #fff;
    margin: 4px 0;
}

.imgoverlay button{
    margin: 16px 0px 0px 0;
}
.imgoverlay img{
    height: 10%;
}

/* Home */
.home{
    position: absolute;
}

/* Hero */
.heroImgs {
    display: flex;
    flex-direction: row;
}

.heroImg {
    border-radius: 8px;
    aspect-ratio: 3/2;
    flex: 1;
    margin: 8px;
    background-size: cover;
    background-position: center;
    -webkit-filter: grayscale(80%); /* Safari 6.0 - 9.0 */
    filter: grayscale(80%);
    scale: 0.9;
    transition: all .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
}

.heroImg:hover{
    -webkit-filter: none;
    filter: none;
    scale: 1;
}
.heroImg h2{
    padding: 16px;
    background-color: rgba(20,20,20,0.5);
    border-radius: 8px;
    text-align: center;
}

/* Heart, Body, Spirit */
.trainwhole{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}
.trainpart{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 268px;
    margin: 0 16px;
    min-width: 200px;
}
.trainpart img{
    width: 60px;
    height: 60px;
}
.trainpart h3{
    color: var(--accent-color-1);
    margin-left: 8px;
}

@media only screen and (max-width: 600px) {
    .imgoverlay h1 {
        font-size: 1.5em;
    }
}
@media only screen and (max-width: 450px) {
    .imgoverlay h1 {
        font-size: 1.2em;
    }
}
@media only screen and (max-width: 300px) {
    .imgoverlay h1 {
        font-size: 1em;
    }
}