.header{
    border-bottom: 3px solid var(--accent-color-1);
}
.imgcontainer{
  height: 120px;
    display: flex;
    align-items: center;
    justify-content:center;
    /* border-bottom: 3px solid var(--sec-color); */
    display: flex;
    background: repeating-linear-gradient(
      var(--accent-color-2),
      var(--accent-color-2) 10%,
    var(--accent-color-3) 15px,
    var(--accent-color-3) 30px,
    var(--accent-color-5) 30px,
    var(--accent-color-5) 45px,
    var(--accent-color-1) 45px,
    var(--accent-color-1) 70px,
    var(--main-black) 70px,
    var(--main-black) 120px
  );
  padding: 0px;
  margin: 0px;
}
.imgcontainer a{
  margin: 0px;
  padding: 0px;
  height: 100%;
  text-decoration: none;
}
.logo {
    height: 100%;
    /* width: 250px; */
    margin: 0px;
}
.logosmall {
    max-width: 10%;
    margin: 16px 0px;
}
.navbar {
    background-color: var(--main-black);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  
  .navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .navbar li {
    margin: 0 8px;
    position: relative;
  }
  
  .navbar li a {
    display: block;
    color: var(--accent-color-6);
    text-decoration: none;
    font-weight: bold;
    padding: 8px 8px;
    font-size: 14px;
  }
  
  .navbar li:hover > ul {
    display: block;
  }
  
  .navbar .dropdown ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--accent-color-1);
    padding: 0;
    margin-top: 0px;
    z-index: 9999;
  }
  
  .navbar .dropdown ul li {
    margin: 0;
    width: 100%;
  }
  
  .navbar .dropdown ul li a {
    color: var(--accent-color-6);
    padding: 10px 15px;
    font-size: 14px;
    text-decoration: none;
  }
  
  .navbar .dropdown ul li:hover {
    background-color: var(--main-black);
  }
