.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.newsletterContainer{
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-image: url(../../components/img/circle-small.jpeg);
    background-repeat: repeat;
    align-self: stretch;
}
.newsletterContainer h2, .newsletterContainer h3, .newsletterContainer h4, .newsletterContainer h5, .newsletterContainer p{
    color: #eee;
    /* text-shadow: 0px 0px 10px var(--accent-color-1);  */
    background-color: #111;
}
.newsletterContainer p{
    padding: 4px 8px;
}
.newsletterContainer button{
    background-color: #eee;
    color: var(--accent-color-1);
}
.footerlinks{
    margin-bottom: 8px;
}

.lowerFooter{
    /* background-color: white; */
    width: 100vw;
    align-items: center;
    justify-content: center;
    /* color: var(--accent-color-1); */
}
.lowerLeft, .lowerRight{
    padding: 8px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.lowerLeft h4{
    margin: 8px 0;
}
.lowerLeft img, .lowerRight img{
    height: 100px;
    margin: 0 16px;
}
.rowWrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.socials{
    display: flex;
    align-items: center;
    justify-content: center;
}
.socials img{
    width: 40px;
    height: 40px;
}
.lowerFooter .slide{
    display: flex;
    align-items: center;
    justify-content: center;
}
.lowerFooter .slide h5, .lowerFooter .slide h6{
    margin: 4px 0;
}
.lowerFooter .slide .img{
    margin-left: 0px;
}
