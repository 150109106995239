.btn {  
    border: 4px solid white;
    color: var(--main-white);
    padding: 8px 16px;
    margin: 8px;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: var(--main-color);
    letter-spacing: 3px;
    cursor:pointer;
  }
  .btnContainer{
    display: flex;
    justify-content: center;
  }
  .captionImage {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 100%;
    flex-direction: column;
    margin: 16px 0;
  }
  .captionImage img {
    max-width: 90vw;
    max-height: 250px;
    border-radius: 8px;
  }
  .captionImage p {
    margin: 0;
    color: gray;
    font-size: 0.7em;
  }
  .circle {
    border-radius: 99999px;
  }