.tournament-card {
	background-color: var(--accent-color-3);
	border-radius: 16px;
	padding: 8px 16px;
	margin-right: 16px;
	margin-bottom: 16px;
}
.tournament-card h2,
.tournament-card h5,
.tournament-card h3,
.tournament-card p {
	margin: 0px;
	text-align: left;
}
