@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--main-black);
  color: var(--main-white);
  margin:0;
}
.topBanner {
  background-color: var(--accent-color-1);
  padding: 8px 64px;
  display: flex;
  text-align: center;
}
.topBanner h4{
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  color: var(--main-white);
}
h1, h2{
  margin: 8px 0;
}
.newsflash{
  color: var(--accent-color-1);
  font-style: italic;
}
form h4{
  margin: 16px 0 0 0;
}
form h5{
  margin: 4px 0;
}
form h6{
  margin: 2px 0;
}

/* Accent Colors */
:root {
  --main-white: #eee;
  --main-black: #111;
  --accent-color-1: #A44333;
  --accent-color-2: #93b7c8;
  --accent-color-3: #5A8190;
  --accent-color-4: #6ccff6;
  --accent-color-5: #f4df39;
  --accent-color-6: #fbf8cb;

}
.redFilter{
  filter: invert(29%) sepia(69%) saturate(696%) hue-rotate(324deg) brightness(97%) contrast(90%);
}
.blueFilter{
  filter: invert(74%) sepia(28%) saturate(851%) hue-rotate(164deg) brightness(96%) contrast(101%);
}
.whiteFilter{
  filter: invert(100%);
}

/* Imgs */
.pageHero{
  display: flex;
  justify-content: center;
}
.pageHero img{
  width: 70%;
  border-radius: 16px;
}
/*Content*/
div.content {
    max-width: 900px; /* Adjust as needed */
    margin: 0 auto; /* Centers the div */
    line-height: 1.5;
    padding: 0 16px;
    padding-bottom: 16px;
  }

/* Navigation */
nav {
  background-color: var(--accent-color-1);
  color: var(--accent-color-2);
}

nav ul li a {
  color: var(--accent-color-2);
}

/* Buttons */
button {
    font-family: 'Anton', sans-serif;
    font-weight: lighter;
    background-color: var(--accent-color-1);
    color: var(--main-white);
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    border-bottom: 2px solid var(--accent-color-2);
}

button:hover {
  opacity: 0.8;
}

/* Select */
select {
  -webkit-appearance:none;
  -moz-appearance:none;
  -ms-appearance:none;
  appearance:none;

  height: 40px;
  padding: 0 28px 0 8px;
  border: 1px solid var(--accent-color-1);
  outline: 0;
  border-radius: 0;
  margin: 4px 4px;
  /* background-color: var(--accent-color-1); */

  font-family: 'Anton', sans-serif;
    font-weight: lighter;
    color: var(--accent-color-1);
    font-size: 14px;
  background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E') no-repeat, var(--main-white);
  background-position: 96% 50%;
  background-size: 8px;

  cursor: pointer;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

/* Links */
a {
  color: var(--accent-color-4);
  /* text-decoration: none; */
}

a:hover {
  text-decoration: underline;
}

/* Input */
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  border: 1px solid #CCC;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  color: #333;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  outline: none;
  border-color: var(--accent-color-1);
}

input[type="submit"] {
  background-color: var(--accent-color-1);
  color: var(--main-white);
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

input[type="submit"]:hover {
  opacity: 0.8;
}

/* Table */
table {
  border-collapse: collapse;
  max-width: 900px;
  margin: 0 auto;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  color: var(--accent-color-1);
}

table th,
table td {
  padding: 8px;
  text-align: center;
}

table thead {
  background-color: var(--accent-color-1);
  color: var(--main-white);
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 3px solid var(--accent-color-2); /* add a border to separate thead from tbody */
}

table td {
  border-bottom: 1px solid #CCCCCC;
}

table tr:nth-child(even) {
  background-color: #F2F2F2;
}

table tr:hover {
  background-color: var(--accent-color-1);
  color: var(--main-white);
  cursor: pointer;
}

table tbody .boldrow {
  background-color: var(--accent-color-1);
  font-weight: bold;
  color: var(--main-white);
}
.nowrap{
  flex-wrap: nowrap;
}