
.contactContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.contactCard{
    width: 300px;
    min-width: 100px;
    text-align: center;
    margin-bottom: 32px;
    border: 1px solid #718c9b;
    border-radius: 8px;
    padding: 16px;
}
.contactCard h3, .contactCard h6{
    margin: 0 0 4px 0;
}
.contactCard p{
    margin: 16px 0;
}