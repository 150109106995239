ul {
    overflow: visible;
  }
  
  ul.slider {
    justify-content: flex-start;
  }
  li.slide a {
    display: inline;
    padding: 0;
  }
  .coachButton {
    margin: 4px 4px;
    border: none;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border: 1px solid var(--main-color);
    border-radius: 4px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
  }
  .sel{
    color: #fff;
    background-color: var(--accent-color-3);
  }
  .coachButtons > h5{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .buttonGroup {
    display: flex;
    flex-direction: row;
    margin: 0px 0;
    flex-wrap: wrap;
    justify-content: center;
  }
  .buttonGroup > div {
    margin: 0 8px;
  }
  .coachView {
    display: "flex";
    align-items: "center";
    flex-direction: "column";
    overflow: "hidden";
  }

.content {
  margin: 0 8px;
}